import React from 'react';
import Logo from '../../assets/logo_full_br.png';
import LogoTSS from '../../assets/logo_tss.svg';

import styles from './footer.module.scss';

const Footer = () => (
  <footer className={styles.footer}>
    <img className={styles.footer__logo} src={Logo} alt="Logo" />
    <img className={styles.footer__logo} src={LogoTSS} alt="Logo" />
  </footer>
);

export default Footer;
