/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import styles from './slider.module.scss';

const Slider = () => {
  const webcams = [
    {
      id: 1,
      url: 'https://hoktastream2.webcamera.pl/kotelnica_cam_ea8e3d/kotelnica_cam_ea8e3d.stream/playlist.m3u8',
      name: 'Kotelnica Białczańska - kamera 1',
    },
    {
      id: 2,
      url: 'https://hoktastream4.webcamera.pl/kotelnica_cam_d105f8/kotelnica_cam_d105f8.stream/playlist.m3u8',
      name: 'Kotelnica Białczańska - kamera 2',
    },
    {
      id: 3,
      url: 'https://hoktastream2.webcamera.pl/skywind_cam_772102/skywind_cam_772102.stream/playlist.m3u8',
      name: 'Kotelnica Białczańska - kamera 3',
    },
    {
      id: 4,
      url: 'https://hoktastream4.webcamera.pl/kotelnica_cam_0b240f/kotelnica_cam_0b240f.stream/playlist.m3u8',
      name: 'Kotelnica Białczańska - kamera 4',
    },
  ];

  return (
    <div className={styles.container}>
      {webcams.map((stream) => (
        <div className="slider" key={stream.id} />
      ))}
    </div>
  );
};

export default Slider;
