import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html {
  font-size: 0.52vh;
  font-family: 'Lato', sans-serif;
    ::-webkit-scrollbar {
      display: none;
    }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #242424;
  background-color: #FFFFFF;
}


@keyframes slide {
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
}

.slider:nth-child(1) {
  animation-delay: 0s;
}

.slider:nth-child(2) {
  animation-delay: 10s;
}

.slider:nth-child(3) {
  animation-delay: 20s;
}

.slider:nth-child(4) {
  animation-delay: 30s;
}


.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: slide 40s infinite;
  opacity: 0;
  flex-shrink: 0;
  object-fit: cover;
}

.slider:nth-child(1) {
  animation-delay: 0s;
  background-image: url("assets/camera-slides/slide-camera1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider:nth-child(2) {
  animation-delay: 10s;
  background-image: url("assets/camera-slides/slide-camera2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider:nth-child(3) {
  animation-delay: 20s;
  background-image: url("assets/camera-slides/slide-camera3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider:nth-child(4) {
  animation-delay: 30s;
  background-image: url("assets/camera-slides/slide-camera4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

`;

export default GlobalStyle;
