import React from 'react';
import useFetchWeather from 'components/hooks/useFetchWeather';
import useFetchLiftsInfo from 'components/hooks/useFetchLiftsInfo';

import Header from 'components/header/header';
import Lifts from 'components/lifts/lifts';
import Slider from 'components/slider/slider_with_camera';
import Footer from 'components/footer/footer';

import GlobalStyle from '../theme/GlobalStyle';
import styles from './App.module.scss';

const App = () => {
  const { weatherData } = useFetchWeather();
  const { liftsInfo } = useFetchLiftsInfo();

  return (
    <>
      <GlobalStyle />
      <div className={styles.wrapper}>
        <div className={styles.background}>
          <Header weatherData={weatherData} />
          <Lifts liftsInfo={liftsInfo} />
        </div>
        <Slider />
        <img src="assets/banners/banner-portrait-branza-narciarska-to-my.png" alt="banner" />
        <Footer />
      </div>
    </>
  );
};

export default App;
